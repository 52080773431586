.text-prime {
    color: rgb(31, 37, 128);
}

.intro {
    background-color: white;
    display: flex;

    .imgContainer {
        width: 700px;
        height: 700px;
        background-color: #C7C9E1;
        border-radius: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        float: right;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}

.about {
    .p-about {
        margin-bottom: 4px;
    }

    .box {
        border-top: 1px solid #D8D8D9;
        border-bottom: 1px solid #D8D8D9;
        margin-bottom: 16px;

        .content {
            p {
                color: #555555;
                margin-bottom: 0;
            }

            h5 {
                color: #232322;
                margin-bottom: 0;
            }
        }
    }

    // .box-edu {
    //     background-color: #d9f2f5;
    //     border-radius: 10px;

    //     p{
    //         margin-bottom: 0;
    //     }
    // }
}

.services {
    background-color: #f7fcfc;

    .work-card {
        background-color: #f9fdfd;
        box-shadow: 4px 4px 4px rgba(107, 106, 106, 0.1);
        border: 1px solid rgb(241, 239, 239);
        border-radius: 10px;

        .icon-img {
            width: 3rem;
            height: 3rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .tool-box {
        box-sizing: border-box;
        background-color: #f5ffff;
        width: 4rem;
        height: 4rem;
        box-shadow: 4px 4px 4px rgba(107, 106, 106, 0.1);
        border: 1px solid rgb(241, 239, 239);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;


        .tool-image {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    .tooltip {
        background-color: red !important;
    }
}

.projects {

    // background-color: rgb(246, 242, 242);
    .arrow {
        cursor: pointer;
    }

    .project-card {
        cursor: pointer;
        box-shadow: 4px 4px 4px rgba(107, 106, 106, 0.1);
        border: 1px solid rgb(241, 239, 239);
        background-color: #FAFAFB;
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        .project-card-img {
            width: 100%;
            height: 12rem;
            border-radius: 10px;
            background-color: white;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: all ease-in-out 0.5s;
            }


        }

        .project-detail {
            min-height: 11rem;

            h6 {
                color: #1F2580;
            }

            p {
                font-weight: 300;
                color: rgb(43, 42, 42);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                /* Limit to 5 lines, adjust min and max font size as needed */
                -webkit-line-clamp: 6;
                /* Additional styling */
                text-overflow: ellipsis;
            }
        }
    }
}

.contact {
    background-color: #f7fcfc;

    .contact-box {
        box-shadow: 4px 4px 4px rgba(107, 106, 106, 0.1);
        border: 1px solid rgb(241, 239, 239);
        border-radius: 8px;

        p {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}

.footer {
    a {
        text-decoration: none;
    }

    background-color: #1F2580;
}