.navbarr {
    width: 100vw;
    height: 70px;
    background-color: white;
    color: #1F2580;
    position: fixed;
    top: 0;
    z-index: 3;
    transition: all 2s ease;

    a {
        text-decoration: none;
        color: inherit;
        display: flex;
        align-items: center;
    }

    .wrapper {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;

            .logo {
                font-size: 30px;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                margin-right: 40px;
            }

            .itemContainer {
                display: flex;
                align-items: center;
                margin-left: 30px;
                cursor: pointer;

                .icon {
                    font-size: 18px;
                    margin-right: 5px;
                }

                span {
                    font-size: 15px;
                    font-weight: 400;
                }
            }
        }

        .right {

            .hamberger {
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;

                span {
                    width: 100%;
                    height: 3px;
                    background-color: #1F2580;
                    transform-origin: left;
                }
            }
        }
    }

    &.active {
        background-color: #1F2580;
        color: white;

        .hamberger {
            span {
                &:first-child {
                    background-color: white;
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:last-child {
                    background-color: white;
                    transform: rotate(-45deg);
                    transition: all 2s ease;
                }

            }
        }
    }
}

// .sticky {
//     position: fixed;
//     top: 0;
//     background-color: white;
//     z-index: 100;
//     animation: fadeInDown 0.5s ease-in-out 0s 1 normal none running;
//     transition: 0.5s;

//     @keyframes fadeInDown {
//         0% {
//             -webkit-transform: translate3d(0, -100%, 0);
//             opacity: 0;
//             transform: translate3d(0, -100%, 0);
//         }

//         100% {
//             -webkit-transform: translateZ(0);
//             opacity: 1;
//             transform: translateZ(0);
//         }
//     }
// }

.menu {
    width: 300px;
    height: 100%;
    background-color: rgb(31, 37, 128);
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 1s ease;

    &.active {
        right: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-weight: 300;
        color: white;
        width: 70%;
        min-height: 70%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        li {
            font-size: 1rem;
            width: 100%;
            background-color: rgba(57, 64, 161, 0.75);
            cursor: pointer;

            span {
                margin-right: 10px;
            }

            a {
                font-size: inherit;
                color: inherit;
                text-decoration: none;
            }

            &:hover {
                font-weight: 500;
                transform: scale(1.03);
                border-radius: 6px;
                background-color: rgb(2, 9, 120);
            }
        }
    }

    .menu-footer {
        width: 70%;
        bottom: 0;
        position: absolute;
        margin-bottom: 10px;

        p {
            font-size: 14px;
            color: rgb(224, 222, 222);
        }
    }

}