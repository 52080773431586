.circle {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4851b7;
    color: white;
}

p {
    margin-bottom: 0;
    padding-bottom: 0;
    font-weight: 300;
    color: #3E3C3C;
}

.btn-outline {
    border: 1px solid #4851B7 !important;
    color: #4851B7 !important;
    transition: all ease-in-out 0.3s !important;

    &:hover {
        background-color: #4851B7 !important;
        color: white !important;
    }
}

.btn-block {
    background-color: rgba(72, 81, 183, 1) !important;
    transition: all ease-in-out 0.3s !important;

    &:hover {
        background-color: rgba(72, 81, 183, 0.9) !important;
    }
}